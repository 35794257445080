<template lang="pug">
.svgWrapper
  component(
    :is="currentIcon"
    v-if="currentIcon"
    :width="svgSize"
    :height="svgSize"
    class="svg-icon"
  )
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue';
import { sizes, defaults } from './constants';

const props = defineProps({
  iconName: {
    type: String,
    required: true,
    default: 'logoShort',
  },
  size: {
    type: String,
    default: defaults.size,
    validator: (val) => Object.keys(sizes).includes(val),
  },
  iconColor: {
    type: String,
    default: defaults.color,
  },
});

const getVarOrColorValue = (str) => {
  return str.startsWith('var:')
    ? str.replace(/^var:/, `var(--${defaults.varPrefix}`) + ')'
    : str;
};

const svgSize = computed(() => sizes[props.size].size);

const colorBind = computed(() => {
  const color = props.iconColor ? props.iconColor : defaults.color;
  return getVarOrColorValue(color);
});
const currentIcon = computed(() =>
  defineAsyncComponent(
    () => import(`../arche/icons/${props.iconName || 'lines'}.vue`),
  ),
);
</script>

<style lang="scss" scoped>
.svgWrapper {
  display: inherit;
  align-items: inherit;
}
.svg-icon {
  color: v-bind('colorBind');
  aspect-ratio: 1/1;

  .iconIsAnimated & {
    animation: 2s ease 0s infinite normal none running sdb05;
    width: 100%;
    height: 100%;
  }
}

@keyframes sdb05 {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(8px);
  }
}
</style>
