import { defineStore, acceptHMRUpdate } from 'pinia';

export const useModalStore = defineStore('ModalStore', {
  state: () => ({
    isModalOpen: false,
  }),
  actions: {
    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },
  },
  getters: {
    getModalOpen: (state) => state.isModalOpen,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalStore, import.meta.hot));
}
