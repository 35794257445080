import { computed } from 'vue';
import { type Button, LinkType } from 'typed-schemas';

import IsFake from '../components/atoms/Links/IsFake.vue';
import IsInput from '../components/atoms/Links/IsInput.vue';
import Link from '../components/atoms/Link.vue';
import IsButton from '../components/atoms/Links/IsButton.vue';

export default function useLinkLogic(props: Partial<Button>) {
  const typesMap = {
    IsInput,
    IsFake,
    Link,
    IsButton,
  };

  const isType = computed(() => {
    const typeKey = props.buttonType as LinkType;
    return typesMap[typeKey] || null;
  });

  const allClasses = computed(() => {
    // Determine direction class
    const directionClass = (() => {
      switch (props.direction) {
        case 'bottom':
          return 'flex-column';
        case 'top':
          return 'flex-column-reverse';
        case 'left':
          return 'flex-row-reverse';
        case 'right':
          return 'flex-row';
        default:
          return ''; // Empty string for default case
      }
    })();

    // Compile all classes, including additional and conditional ones
    const additionalClasses = [
      props.context !== 'none' ? `btn` : '',
      props.size === 'lg' ? 'text-uppercase' : '',
      `btn-${props.context}`,
      `btn-${props.size}`,
      props.isBlock ? 'w-100' : '',
      props.isDisabled ? 'button--disabled disable' : '',
    ].filter(Boolean);

    // Combine textDecorationClass, directionClass, classObjectStrings, and additionalClasses
    return [directionClass, ...additionalClasses].join(' ');
  });

  const ariaDisabled = computed(() => (props.isDisabled ? 'true' : 'false'));

  const tabIndexValue = computed(() => (props.isDisabled ? '-1' : '0'));

  // @click="isDisabled && $event.preventDefault()"
  const preventDisabledClick = (event: MouseEvent) => {
    if (props.isDisabled) event.preventDefault();
  };

  return {
    allClasses,
    ariaDisabled,
    tabIndexValue,
    preventDisabledClick,
    isType,
  };
}
