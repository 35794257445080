export const sizes = {
  xs: {
    size: 8,
  },
  sm: {
    size: 16,
  },
  md: {
    size: 24,
  },
  lg: {
    size: 32,
  },
  xl: {
    size: 48,
  },
  xxl: {
    size: 64,
  },
  full: {
    size: '100%',
  },
};
export const defaults = {
  color: 'var:primary',
  varPrefix: 'ti-',
  size: 'md',
};
