<template lang="pug">
a.link(
  :href="`tel:${phone || text}`"
  rel="noopener noreferrer nofollow"
  target="_blank"
  @click="handleClick"
)
  slot(v-if='$slots.prevLabel' name='prevLabel' :text="text")
    | Phone:&nbsp;{{ text }}
  template(v-else)
    | {{ text }}
  slot
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { type PhoneLink, defaultPhoneLink } from 'typed-schemas';
withDefaults(defineProps<PhoneLink>(), defaultPhoneLink);

const handleClick = () => {
  const gtm = useGtm();
  if (gtm) {
    gtm.trackEvent({
      event: 'email_link',
      value: parseInt('1', 10),
      currency: 'EUR',
      event_category: 'leads',
      event_label: `User Contact by Phone`,
    });
  }
};
</script>
