<template lang="pug">
component.link.gap-2.align-items-center(
  :is='isType'
  :text='text'
  :label='label'
  :link='link'
  :class="allClasses"
)
  template(v-if='$slots.prevLabel' #prevLabel)
    slot(name='prevLabel' :text="text")
  slot(name="icon")
</template>

<script lang="ts" setup scoped>
import { defineProps, withDefaults } from 'vue';
import { type Link, defaultLink } from 'typed-schemas';

import useLinkLogic from '../../composables/useLinkLogic';

const props = withDefaults(defineProps<Partial<Link>>(), defaultLink);

const { allClasses, hoverColorBind, isType } = useLinkLogic(props);
</script>

<style lang="scss" scoped>
.link {
  &.hasHover:hover {
    color: v-bind('hoverColorBind') !important;
  }
}
</style>
