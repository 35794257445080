/// ////////
// Defaults
export const defaultBaseLink = {
    id: 1,
    label: '',
    link: '',
    text: '',
};
export const defaultEmailLink = {
    ...defaultBaseLink,
    email: '',
    subject: 'Query From Website',
};
export const defaultPhoneLink = {
    ...defaultBaseLink,
    phone: '',
};
export const defaultButtonLink = {
    id: defaultBaseLink.id,
    text: defaultBaseLink.text,
};
export const defaultLink = {
    ...defaultBaseLink,
    fontWeight: 'normal',
    hoverColor: '',
    isUnderLine: 'underline',
    linkColor: '',
    linkType: 'IsInternal',
};
export const defaultButton = {
    ariaLabel: '',
    buttonType: 'Link',
    context: '',
    direction: 'right',
    fontWeight: defaultLink.fontWeight,
    id: defaultBaseLink.id,
    isBlock: false,
    isDisabled: false,
    label: defaultBaseLink.label,
    size: '',
    text: defaultBaseLink.text,
};
export const defaultNavItem = {
    fontWeight: defaultLink.fontWeight,
    id: defaultLink.id,
    isUnderLine: defaultLink.isUnderLine,
    link: defaultLink.link,
    linkColor: defaultLink.linkColor,
    text: defaultLink.text,
    linkType: defaultLink.linkType,
    variant: 'default',
};
export const defaultLinkModel = {
    ...defaultLink,
    children: [],
    columns: '1',
    is_submenu: 'no',
    level: '0',
    order: 0,
    parent: '0',
    subMenu: [],
    variation: '',
};
/// ////////
// Placeholder
export const placeholderBaseLink = {
    id: 1,
    label: 'Some link Label',
    link: 'https://domain/some-link',
    text: 'Some Descriptive Text For The Link',
};
export const placeholderEmailLink = {
    ...placeholderBaseLink,
    email: 'email@example.com',
    subject: 'Query From Website',
};
export const placeholderPhoneLink = {
    ...placeholderBaseLink,
    phone: '123456789',
};
export const placeholderLink = {
    ...placeholderBaseLink,
    fontWeight: 'normal',
    hoverColor: '',
    isUnderLine: 'underline',
    linkColor: 'none',
    linkType: 'IsInternal',
};
export const placeholderButton = {
    ariaLabel: null,
    buttonType: 'Link',
    context: 'primary',
    direction: 'right',
    id: placeholderBaseLink.id,
    isBlock: false,
    isDisabled: false,
    label: placeholderBaseLink.label,
    linkType: 'IsInternal',
    size: 'md',
    text: placeholderBaseLink.text,
};
export const placeholderNavItem = {
    fontWeight: placeholderLink.fontWeight,
    id: placeholderLink.id,
    isUnderLine: 'none',
    link: placeholderLink.link,
    linkColor: placeholderLink.linkColor,
    linkType: placeholderLink.linkType,
    text: placeholderLink.text,
    variant: 'default',
};
export const placeholderLinkModel = {
    ...placeholderLink,
    children: [],
    columns: '2',
    id: 1,
    is_submenu: 'yes',
    level: '0',
    order: 1,
    parent: '12',
    subMenu: [],
    variation: 'nested',
};
