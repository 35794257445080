<template lang="pug">
component(:is='tagType' :class='computedClasses')
  slot
</template>

<script setup>
import { computed, toRefs } from 'vue';

const props = defineProps({
  tag: {
    type: String,
    default: 'h1',
    validator: (value) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
  },
  size: {
    type: String,
    default: 'none',
    validator: (value) =>
      ['1', '2', '3', '4', '5', '6', 'none'].includes(value),
  },
  context: {
    type: String,
    default: 'none',
    validator: (value) =>
      [
        'none',
        'primary',
        'secondary',
        'dark',
        'light',
        'white',
        'danger',
        'success',
      ].includes(value),
  },
});

const { tag, size, context } = toRefs(props);

const tagType = computed(() => tag.value);

const computedClasses = computed(() => {
  const classes = [];

  // Add size
  classes.push(`h${size.value}`);

  // Add Bootstrap text color class
  if (context.value !== 'none') {
    classes.push(`text-${context.value}`);
  }

  return classes;
});
</script>
