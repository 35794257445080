<template lang="pug">
.iconWrapper(:class="[{'iconIsAnimated': isAnimated}]")
  EcreativAtomsIcony(
    v-if="isCustom",
    :iconName='iconName',
    :size="iconSize",
    :iconColor="iconColor",
    v-bind:="$attrs",
    :="$props"
  )
  Icon(
    v-else,
    :name='fullPathName',
    v-bind:="$attrs",
    :="$props"
  )
</template>

<script setup>
import { computed } from 'vue';
import EcreativAtomsIcony from './Icony.vue';

const props = defineProps({
  iconName: {
    type: String,
    required: true,
    default: 'logoShort',
  },
  isCustom: {
    type: Boolean,
    default: false,
  },
  isAnimated: {
    type: Boolean,
    default: false,
  },
  iconColor: {
    type: String,
    default: 'currentColor',
  },
  iconSize: {
    type: String,
    default: 'md',
    validator: (value) =>
      ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'full'].includes(value),
  },
});

const fullPathName = computed(() => {
  if (props.iconName.includes(':')) {
    return props.iconName;
  } else {
    return `bi:${props.iconName}`;
  }
});
</script>

<style scoped lang="scss">
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconIsAnimated {
  width: 16px;
  opacity: 0.6;
}
</style>
