export const defaultArticleModel = {
    category: '',
    content: '',
    date: '',
    id: 0,
    image: '',
    link: '',
    linkLabel: '',
    title: '',
    slug: '',
    truncateContent: '',
};
export const placeholderArticleModel = {
    category: 'Sample Category',
    content: 'Some Content Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident veniam natus aut ipsam dignissimos, veritatis quae explicabo voluptates animi molestiae soluta totam. Ad blanditiis quo assumenda quos laborum ullam ipsa!',
    date: new Date().toISOString(),
    id: 1,
    image: 'https://placehold-co.translate.goog/400x400',
    link: '#-some-link',
    linkLabel: 'More Some Text',
    title: 'Some Title Lorem ipsum dolor sit amet.',
    slug: 'lorem-ipsum-dolor-sit-amet',
    truncateContent: 'Some Content Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident veniam natus aut...',
};
